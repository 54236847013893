<template>
  <div class="create-picture">
    <div class="header-title">Visit Photo：</div>
    <div class="visit-img">
      <div class="pic-left">
        <div>Check In</div>
        <div class="pic-list">
          <van-uploader :disabled="visitInfo.isEdit == 0" v-model="signInFileList2" :before-read="beforeRead" :after-read="(files, detail) => afterRead(files, detail, 'signInFileList')" />
        </div>
      </div>
      <div class="pic-right">
        <div>Check Out</div>
        <div class="pic-list">
          <van-uploader :disabled="visitInfo.isEdit == 0" v-model="signOutFileList2" :before-read="beforeRead" :after-read="(files, detail) => afterRead(files, detail, 'signOutFileList')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixins from './createMixins'
export default {
  name: 'Picture',
  mixins: [CreateMixins],
  props: {
    signInFileList: {
      type: Array,
      default: () => []
    },
    signOutFileList: {
      type: Array,
      default: () => []
    },
    visitInfo: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      signInFileList2: this.signInFileList,
      signOutFileList2: this.signOutFileList
    }

  },
  methods: {}
}
</script>

<style lang="less" scoped>
.create-picture {
  font-size: 12px;
  .header-title {
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px 0 10px;
  }
  .visit-img {
    display: flex;
    padding: 10px;
    margin: 10px 0;
    border-top: 1px solid #dce5f6;
    .pic-left,
    .pic-right {
      width: 50%;
      min-height: 130px;
    }
    .pic-list {
      display: flex;
      flex-wrap: wrap;
      .icon-img {
        margin-right: 5px;
      }
    }
  }
  .product-img {
    display: flex;
    flex-wrap: wrap;
    min-height: 130px;
    padding: 10px;
    margin: 10px 0;
    border-top: 1px solid #dce5f6;
    border-bottom: 1px solid #dce5f6;
    .icon-img {
      margin-right: 5px;
    }
  }
  .icon-img {
    width: 40px;
    height: 40px;
  }
  /deep/.van-uploader {
    width: 100%;
    padding-top: 5px;
  }
  /deep/.van-uploader__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // border: 1px solid #007bff;
    border-radius: 10px;
    .van-uploader__upload {
      width: 70px;
      height: 70px;
      margin: 0;
    }
    .van-uploader__upload-icon {
      font-size: 18px;
    }
    .van-uploader__preview {
      width: 70px;
      height: 70px;
      margin: 0 5px 5px 0;
    }
    .van-uploader__file {
      width: 100%;
      height: 100%;
    }
    .van-uploader__preview-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
